const resource = {
    en: {
        heading: 'Set Status of Matrix',
        in_edit: 'IN EDIT',
        for_review: 'FOR REVIEW',
        public: 'PUBLIC',
        finish: 'FINISH',
    },
    de: {
        heading: 'Status Ändern',
        in_edit: 'IN BEARBEITUNG',
        for_review: 'FÜR REVIEW',
        public: 'ÖFFENTLICH',
        finish: 'VOLLENDET',
    }
}

export default resource;