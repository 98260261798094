<template>
    <BaseModal ref="base_modal" @close="closeModal">
        <label class="modal-heading" v-html="resource[lang].heading" />

        <form @submit.prevent="uploadAttachment">
            <q-file clearable square
                color="white" 
                standout="bg-accent text-white"
                v-model="file" 
                :label="resource[lang].file"
                :rules="[val => val || resource[lang].choose_file]"
            >
                <template v-slot:prepend>
                    <q-icon name="fas fa-paperclip" />
                </template>

                <template v-slot:hint>
                    {{ resource[lang].click_to_select }}
                </template>
            </q-file>

            <div v-if="file" class="file-properties">
                <label class="modal-heading-2" v-html="resource[lang].file_properties" />
                
                <InputText :label="resource[lang].name" v-model="name" />
                <InputTextArea :label="resource[lang].description" v-model="description" />
            </div>

            <div class="actions flex">
                <Button color="accent" :label="resource[lang].upload" submit />
                <Button color="white" :label="resource[lang].cancel" @click="closeModal" />
            </div>
            
        </form>
    </BaseModal>
</template>

<script>
import resource from '@/assets/resources/matrix-attachment-upload-modal-resource'

import BaseModal from '@/components/modals/BaseModal'
import Button from '@/components/basic/Button'
import InputText from '@/components/basic/InputText'
import InputTextArea from '@/components/basic/InputTextArea'

import { promisePostRequest } from '@/assets/js/axios-utilities'

export default {
    props: {
        matrix_id: {
            type: Number,
            required: true,
        }
    },
    components: {
        BaseModal,
        Button,
        InputText,
        InputTextArea
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            file: null,
            name: '',
            description: '',

            resource
        }
    },
    methods: {
        // Requests
        uploadAttachmentRequest() {
            const form_data = new FormData();
            form_data.append('file', this.file);
            form_data.append('name', this.name);
            form_data.append('matrix_id', this.matrix_id);
            form_data.append('description', this.description);
            return promisePostRequest('attachment', form_data);
        },

        // Actions
        async uploadAttachment() {
            this.$store.dispatch('loading', true);
            const response = await this.uploadAttachmentRequest();
            this.$store.dispatch('loading', false);

            this.$emit('upload', response.data.file);
            this.$refs.base_modal.closeModal();
        },
        closeModal() {
            this.$emit('cancel');
            this.$refs.base_modal.closeModal();
        }
    },
    watch: {
        file: function(val) {
            this.name = val.name ? val.name : '';
        }
    },
    emits: ['upload', 'cancel']
}
</script>

<style lang="scss" scoped>

.actions {
    margin-block-start: 2rem;

    justify-content: space-between;
}

.file-properties > * + * {
    margin-block-start: 1rem;
}

.top-bar {
    justify-content: space-between;

    & > * {
        margin: 0 !important;
    }
}

</style>