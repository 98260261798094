<template>
    <div class="wrapper" v-if="matrix">
        <div class="basic-form">
            <h2 class="basic-form-heading-1" v-html="resource[lang].heading" />

            <h3 class="basic-form-heading-2" v-html="resource[lang].editors" />

            <UserInfoCard 
                class="user"
                v-for="user in matrix.editors" 
                :key="user.id" 
                :user="user"
                :remove="share == true"
                @remove="showRemoveSharedUserModal(user.id)"
            />

            <div v-if="matrix.editors.length === 0" class="not-found">
                <p class="text" v-html="resource[lang].no_users" />
            </div>

            <h3 class="basic-form-heading-2" v-html="resource[lang].reviewers" />

            <UserInfoCard 
                class="user"
                v-for="user in matrix.reviewers" 
                :key="user.id" 
                :user="user"
                :remove="share == true"
                @remove="showRemoveSharedUserModal(user.id)"
            />
            <div v-if="matrix.reviewers.length === 0" class="not-found">
                <p class="text" v-html="resource[lang].no_users" />
            </div>
        </div>

        <RemoveSharedUserModal 
            v-if="showRemoveSharedUserModalFlag"
            @confirm="removeSharedUser"
            @cancel="cancelRemoveSharedUser"
        />

    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import resource from '@/assets/resources/matrix-shared-users-resource'

import RemoveSharedUserModal from './modals/RemoveSharedUserModal.vue'
import UserInfoCard from '@/components/UserInfoCard'

import { promisePutRequest } from '@/assets/js/axios-utilities'
import { getAbility } from '@/assets/js/user-abilities'

export default {
    props: {
        matrix: Object
    },
    components: {
        RemoveSharedUserModal,
        UserInfoCard,
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            showRemoveSharedUserModalFlag: false,
            remove_user_id: null,

            // user abilities
            share: false,

            resource
        }
    },
    computed: {
        ...mapGetters(['user'])
    },
    created() {
        this.getAbilities();
    },
    methods: {
        // Init
        async getAbilities() {
            this.share = await getAbility('share-matrix', this.user, this.matrix);
        },

        // Requests
        removeSharedUserRequest() {
            const request_body = {
                user_id: this.remove_user_id
            }
            return promisePutRequest(`unshare-matrix/${this.matrix.id}`, request_body)
        },

        // Actions
        showRemoveSharedUserModal(id) {
            if (this.share) {
                this.remove_user_id = id
                this.showRemoveSharedUserModalFlag = true
                this.$store.dispatch('modal', true)
            }
        },
        cancelRemoveSharedUser() {
            this.remove_user_id = null
            this.showRemoveSharedUserModalFlag = false
        },
        async removeSharedUser() {
            this.$store.dispatch('loading', true)
            await this.removeSharedUserRequest()
            this.$store.dispatch('loading', false)

            this.remove_user_id = null
            this.showRemoveSharedUserModalFlag = false
            this.$emit('reload');
        }


    },
    watch: {
        matrix: function() {
            this.getAbilities();
        },
        user: function() {
            this.getAbilities();
        }
    },
    emits: ['reload']
}
</script>

<style lang="scss" scoped>

.user + .user {
    margin-block-start: 0.5rem;
}

</style>