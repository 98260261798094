const resource = {
    en: {
        tooltips: {
            edit: 'edit',
            remove: 'delete'
        }
    },
    de: {
        tooltips: {
            edit: 'bearbeiten',
            remove: 'löschen'
        }
    }
}

export default resource;