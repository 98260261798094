<template>
    <div class="wrapper" v-if="matrix">
        <div class="basic-form">
            <h2 class="basic-form-heading-1" v-html="resource[lang].heading" />

            <div>
                <Button color="primary" v-if="user" :label="resource[lang].add_attachment" @click="showAttachmentUploadModal" />
            </div>

            <div v-if="matrix.attachments.length > 0">
                <MatrixAttachmentListItem 
                    class="list-item"
                    v-for="attachment in matrix.attachments"
                    :key="attachment.id"
                    :attachment="attachment" 
                    @edit-attachment="showEditAttachmentModal(attachment)"
                    @delete-attachment="showDeleteAttachmentModal(attachment.id)"
                />
            </div>
            <div class="no-attachments" v-else>
                <p class="text" v-html="resource[lang].no_attachments" />
            </div>

        </div>

        <DeleteModal
            v-if="showDeleteAttachmentModalFlag"
            @confirm="deleteAttachment"
            @cancel="cancelDeleteAttachmentModal"
        />

        <MatrixAttachmentEditModal 
            v-if="showEditAttachmentModalFlag"
            :attachment="edit_attachment"
            @update="editAttachment"
            @cancel="cancelEditAttachmentModal"
        />

        <MatrixAttachmentUploadModal
            v-if="showAttachmentUploadModalFlag"
            :matrix_id="matrix.id"
            @upload="addAttachment"
            @cancel="cancelAttachmentUploadModal"
        />
        
    </div>
</template>

<script>
import resource from '@/assets/resources/matrix-attachments-resource'

import Button from '@/components/basic/Button'

import DeleteModal from '@/components/modals/DeleteModal'
import MatrixAttachmentEditModal from '@/components/modals/MatrixAttachmentEditModal'
import MatrixAttachmentListItem from '@/components/MatrixAttachmentListItem'
import MatrixAttachmentUploadModal from '@/components/modals/MatrixAttachmentUploadModal'

import { promiseDeleteRequest } from '@/assets/js/axios-utilities'
import { mapGetters } from 'vuex'


export default {
    props: {
        matrix: Object
    },
    components: {
        Button,
        DeleteModal,
        MatrixAttachmentEditModal,
        MatrixAttachmentListItem,
        MatrixAttachmentUploadModal
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            showAttachmentUploadModalFlag: false,
            showEditAttachmentModalFlag: false,
            showDeleteAttachmentModalFlag: false,

            edit_attachment: null,
            delete_attachment_id: null,

            resource
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    methods: {
        // Request
        deleteAttachmentRequest() {
            return promiseDeleteRequest(`attachment/${this.delete_attachment_id}`);
        },

        // Actions
        showAttachmentUploadModal() {
            this.showAttachmentUploadModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        cancelAttachmentUploadModal() {
            this.showAttachmentUploadModalFlag = false;
        },
        addAttachment() {
            this.showAttachmentUploadModalFlag = false;
            this.$emit('reload');
        },
        showEditAttachmentModal(attachment) {
            this.edit_attachment = attachment;
            this.showEditAttachmentModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        cancelEditAttachmentModal() {
            this.edit_attachment = null;
            this.showEditAttachmentModalFlag = false;
        },
        editAttachment() {
            this.$emit('reload');
            this.showEditAttachmentModalFlag = false;
        },
        showDeleteAttachmentModal(attachment_id) {
            this.delete_attachment_id = attachment_id;
            this.showDeleteAttachmentModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        cancelDeleteAttachmentModal() {
            this.delete_attachment_id = null;
            this.showDeleteAttachmentModalFlag = false;
        },
        async deleteAttachment() {
            this.$store.dispatch('loading', true);
            await this.deleteAttachmentRequest();
            this.$store.dispatch('loading', false);

            this.showDeleteModalFlag = false;
            this.delete_attachment_id = null;
            this.$emit('reload');
        }

    },
    emits: ['reload']
}
</script>

<style lang="scss" scoped>

.list-item {
    margin-top: 1rem;
}

.no-attachments {
    padding: 1rem;
}

</style>
