const resource = {
    en: {
        heading: 'Matrix Shared with Users',
        editors: 'Editors',
        reviewers: 'Reviewers',
        no_users: 'No Users'
    },
    de: {
        heading: 'Benutzer*innen für die die Matrix freigegeben ist',
        editors: 'Zum Bearbeiten',
        reviewers: 'Für Review',
        no_users: 'Keine Benutzer*innen'
    }
}

export default resource;