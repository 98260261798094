// fonts
import { importFonts } from '@/assets/js/pdf/importFonts'
import { jsPDF } from 'jspdf'

async function generatePdf(matrix) {
    await importFonts();

    // generate pdf
    const doc = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
    });

    const HEADING_FONT = "AvenirHeavy";
    const SUB_HEADING_FONT = "Avenir"
    const CONTENT_HEADING_FONT = "AvenirHeavy";
    const CONTENT_FONT = "Avenir";
    const X_POSITIONS = { 1: 10, 2: 85, 3: 183 };
    const PARAGRAPH_LINE_HEIGHT = 4.5;

    /* ----------------------------------------- */
    /* --------------- SECTION 1 --------------- */
    /* ----------------------------------------- */
    // GENERAL INFORMATION
    let section_name = 'General Information';
    let page_number = 1;
    addFooter(page_number);

    let next_item_y_pos = 9;

    // Heading
    // one line fits 80 ch
    // one line has a height of 7mm
    doc
        .setFont(HEADING_FONT, "normal")
        .setFontSize(20)
        .setTextColor(90, 129, 190)
        .text(
        matrix.name.toUpperCase(),
        10, // x coordinate
        next_item_y_pos, // y coordinate
        { 
            maxWidth: doc.internal.pageSize.width - 12 - 14, 
            lineHeightFactor: 1.0,
            baseline: 'hanging',
        }); // options

    // in order to calculate how many space the heading needs
    next_item_y_pos += Math.ceil(matrix.name.length / 65) * 7 + 3;

    // Sub Heading
    // one line fits 160 ch
    // one line has a height of 3mm
    doc
        .setFont(SUB_HEADING_FONT, "italic")
        .setFontSize(10)
        .setTextColor(90, 129, 190)
        .text(
        matrix.topic,
        10, // x coordinate
        next_item_y_pos, // y coordinate
        { 
            maxWidth: doc.internal.pageSize.width - 12 - 20, 
            baseline: 'hanging', 
        }); // options
          
    // in order to calculate how many space the heading needs
    next_item_y_pos += Math.ceil(matrix.topic.length / 160) * 3;

    // save the y position where columns start
    let page_body_y_start = next_item_y_pos + 11;
    let column_height = doc.internal.pageSize.height - page_body_y_start - 15;
    

    // first column -> width of 90
    let column_number = 1;
    let available_left_column_space = column_height;
    next_item_y_pos = page_body_y_start;

    addGeneralInfoMetaData();

    // second column -> width of 110
    available_left_column_space = column_height;
    next_item_y_pos = page_body_y_start;
    column_number = 2;

    addParagraph('Substantive Concepts', matrix.substantive_concepts);
    addParagraph('Historical Thinking Concepts', matrix.historical_thinking_concepts);

    /* ----------------------------------------- */
    /* --------------- SECTION 2 --------------- */
    /* ----------------------------------------- */
    // LEARNING ENVIRONMENT AND TARGET GROUP
    // todo
    section_name = 'Learning Environment and Target Group';
    nextPage(true);

    addTargetGroupMetaData();
    available_left_column_space = column_height;
    next_item_y_pos = page_body_y_start;
    column_number = 2;

    addParagraph('Target Group Composition', matrix.target_group_composition);
    addParagraph('Subject Knowledge', matrix.subject_knowledge);
    addParagraph('Methodological Knowledge', matrix.methodological_knowledge);

    /* ----------------------------------------- */
    /* --------------- SECTION 3 --------------- */
    /* ----------------------------------------- */
    // TEACHER'S ASSUMPTIONS AND BELIEFS
    // todo
    section_name = 'Teachers Assumptions and Beliefs';
    nextPage(true);

    available_left_column_space = column_height;
    next_item_y_pos = page_body_y_start;
    column_number = 2;

    addParagraph('Expected Quality of Learning Process', matrix.expected_quality_of_learning_process);
    addParagraph('Expected Subject Specific Knowledge', matrix.expected_subject_specific_knowledge);
    addParagraph('Expected Methodological Knowlegde', matrix.expected_methodological_knowledge);
    addParagraph('Criteria for Success', matrix.criteria_for_success);

    // reset column number
    column_number = 1;

    /* ----------------------------------------- */
    /* --------------- SECTION 4 --------------- */
    /* ----------------------------------------- */
    // SEQUENCE TABLE
    // todo
    section_name = 'Sequence Table';
    nextPage(false);

    addTableStructure(true);

    addSequences(matrix.sequences);

    /* ----------------------------------------- */
    /* --------------- SECTION 5 --------------- */
    /* ----------------------------------------- */
    // ATTACHMENTS
    // todo
    section_name = 'Attachments';
    nextPage(true);

    addAttachments(matrix.attachments);

    
    /* ----------------------------------------- */
    /* ----------------- SAVE ------------------ */
    /* ----------------------------------------- */
    doc.save(`${matrix.name}.pdf`);

    function addHeader() {
        // Header Text
        doc
            .setFont("Avenir", "normal")
            .setFontSize(8)
            .setTextColor(90, 129, 190)
            .text(
            `${matrix.name.toUpperCase()} – ${matrix.teachers.toUpperCase()}`,
            doc.internal.pageSize.width - 14, // x coordinate
            9, // y coordinate
            { 
                align: 'right', 
                baseline: 'hanging'
            });   // options
        // todo: Add Line
    }

    function addFooter(page_number) {
        // Footer Text
        doc
            .setFont("Avenir", "italic")
            .setFontSize(7)
            .setTextColor(30, 30, 30)
            .text(
            "© Digital Matrix for Designing History Courses developed by Alois Ecker with the collaboration of Bettina Paireder.",
            12, // x coordinate
            doc.internal.pageSize.height - 6, // y coordinate
            );

        // Page Number
        doc
            .setFont("Avenir", "normal")
            .setFontSize(7)
            .setTextColor(30, 30, 30)
            .text(
            `${page_number}`,
            doc.internal.pageSize.width - 11, // x coordinate
            doc.internal.pageSize.height - 6, // y coordinate
            { align: 'right' } // options
            );
    }

    function addGeneralInfoMetaData() {
        doc
            .setFont(CONTENT_HEADING_FONT, "normal")
            .setFontSize(10)
            .setTextColor(125, 128, 103)
            .text(
            section_name.toUpperCase(),
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options

        next_item_y_pos += 3 + 7; // line height + space

        doc
            .setFont(CONTENT_HEADING_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            'Authors(s) / Teacher(s)'.toUpperCase(),
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options

        next_item_y_pos += 3 + 1.5; // line height + space

        doc
            .setFont(CONTENT_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            matrix.teachers,
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options
        
        next_item_y_pos += Math.ceil(matrix.teachers.length / 42) * 5 + 6; // lines * line height + space

        doc
            .setFont(CONTENT_HEADING_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            'Created'.toUpperCase(),
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options

        next_item_y_pos += 3 + 1.5; // line height + space

        doc
            .setFont(CONTENT_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            getTimeString(matrix.created_at),
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options

        next_item_y_pos += 3 + 1.5; // line height + space

        doc
            .setFont(CONTENT_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            matrix.owner.full_name,
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options

        next_item_y_pos += Math.ceil(matrix.owner.full_name.length / 42) * 5 + 6; // lines * line height + space

        doc
            .setFont(CONTENT_HEADING_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            'Updated at'.toUpperCase(),
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options

        next_item_y_pos += 3 + 1.5; // line height + space

        doc
            .setFont(CONTENT_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            getTimeString(matrix.updated_at),
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options

        next_item_y_pos += 4 + 7; // line height + space

        doc
            .setFont(CONTENT_HEADING_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            'Course Nr. / Module Nr.'.toUpperCase(),
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options

        next_item_y_pos += 3 + 1.5; // line height + space

        doc
            .setFont(CONTENT_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            matrix.course_nr,
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options

        next_item_y_pos += Math.ceil(matrix.course_nr.length / 42) * 5 + 6; // lines * line height + space

        doc
            .setFont(CONTENT_HEADING_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            'Temporal Framework'.toUpperCase(),
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options

        next_item_y_pos += 3 + 1.5; // line height + space

        doc
            .setFont(CONTENT_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            matrix.temporal_framework.substring(0, matrix.temporal_framework.length - 1),
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options

        next_item_y_pos += Math.ceil((matrix.temporal_framework.length - 1) / 42) * 5 + 6; // lines * line height + space

        doc
            .setFont(CONTENT_HEADING_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            'Stage / Level'.toUpperCase(),
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options

        next_item_y_pos += 3 + 1.5; // line height + space

        doc
            .setFont(CONTENT_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            matrix.stage,
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options
    }

    function addTargetGroupMetaData() {
        doc
            .setFont(CONTENT_HEADING_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            'Male Participants'.toUpperCase(),
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options

        next_item_y_pos += 3 + 1.5; // line height + space

        doc
            .setFont(CONTENT_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            String(matrix.male_participants),
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options
        
        next_item_y_pos += 5 + 6; // line height + space

        doc
            .setFont(CONTENT_HEADING_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            'Female Participants'.toUpperCase(),
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options

        next_item_y_pos += 3 + 1.5; // line height + space

        doc
            .setFont(CONTENT_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            String(matrix.female_participants),
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options
        
        next_item_y_pos += 5 + 6; // line height + space

        doc
            .setFont(CONTENT_HEADING_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            'Divers Participants'.toUpperCase(),
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options

        next_item_y_pos += 3 + 1.5; // line height + space

        doc
            .setFont(CONTENT_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
            String(matrix.divers_participants),
            X_POSITIONS[column_number], // x coordinate
            next_item_y_pos, // y coordinate
            { 
                maxWidth: 70, 
                baseline: 'hanging', 
            }); // options
        
        next_item_y_pos += 5 + 6; // line height + space
    }

    function addParagraph(paragraph_heading, paragraph_content) {
        
        if (available_left_column_space < 4 * PARAGRAPH_LINE_HEIGHT) {
            nextSection();
        }

        // start with writing heading
        doc
            .setFont(CONTENT_HEADING_FONT, "normal")
            .setFontSize(10)
            .setTextColor(90, 129, 190)
            .text(
                paragraph_heading.toUpperCase(),
                X_POSITIONS[column_number], // x coordinate
                next_item_y_pos, // y coordinate
                { 
                    maxWidth: 85, 
                    baseline: 'hanging', 
                }   // options
            ); 

        next_item_y_pos += 3 + 1.5; // line height + space
        available_left_column_space -= (3 + 1.5);

        // write content
        addParagraphContent(paragraph_content);
    }

    function addParagraphContent(content) {
        // check if whole text fits in this column
        if ((content.length / 55) * 6 < available_left_column_space) {
            doc
                .setFont(CONTENT_FONT, "normal")
                .setFontSize(10)
                .setTextColor(30, 30, 30)
                .text(
                    content,
                    X_POSITIONS[column_number], // x coordinate
                    next_item_y_pos, // y coordinate
                    { 
                        // align: 'justify',
                        maxWidth: 93, 
                        baseline: 'hanging', 
                    }
                ); // options

            next_item_y_pos += Math.ceil(content.length / 55) * PARAGRAPH_LINE_HEIGHT + 6; // line height + space
            available_left_column_space -= (Math.ceil(content.length / 55) * PARAGRAPH_LINE_HEIGHT + 6);

        } else {
            // if the column is not long enough calculate which part should
            // be printed in the column and execute the function again
            // for the rest of the string
            const left_lines = Math.floor(available_left_column_space / PARAGRAPH_LINE_HEIGHT);
            const index = content.substring((left_lines - 1) * 55).indexOf(' ');
            const string_to_print = content.substring(0, (left_lines - 1) * 55 + index);
            const rest_of_string = content.substring((left_lines - 1) * 55 + index);

            doc
                .setFont(CONTENT_FONT, "normal")
                .setFontSize(10)
                .setTextColor(30, 30, 30)
                .text(
                    string_to_print,
                X_POSITIONS[column_number], // x coordinate
                next_item_y_pos, // y coordinate
                { 
                    maxWidth: 93, 
                    baseline: 'hanging', 
                }); // options

            nextSection(false);
            addParagraphContent(rest_of_string);
        }
    }

    function getTimeString(date_time) {
        const date_object = new Date(date_time);
        
        const DD = String(date_object.getDate()).padStart(2, '0');
        const MM = String(date_object.getMonth() + 1).padStart(2, '0'); //January is 0!
        const YYYY = date_object.getFullYear();
        const hh = String(date_object.getHours()).padStart(2, '0');
        const mm = String(date_object.getMinutes()).padStart(2, '0');
        const ss = String(date_object.getSeconds()).padStart(2, '0');
        return `${YYYY}-${MM}-${DD} / ${hh}:${mm}:${ss}`;
    }

    // true if attachment section
    function nextSection(attachment) {
        if (column_number === 1  || column_number === 2) {
            column_number += 1;
            available_left_column_space = column_height;
            next_item_y_pos = page_body_y_start;

        } else if (column_number === 3) {
            page_body_y_start = 25;
            column_height = doc.internal.pageSize.height - page_body_y_start - 15;
            column_number = 1;

            doc.addPage();
            page_number += 1;
            addHeader();
            addFooter(page_number);

            next_item_y_pos = page_body_y_start;
            available_left_column_space = column_height;

            doc
                .setFont(CONTENT_HEADING_FONT, "normal")
                .setFontSize(10)
                .setTextColor(125, 128, 103)
                .text(
                section_name.toUpperCase(),
                X_POSITIONS[column_number], // x coordinate
                next_item_y_pos, // y coordinate
                { 
                    maxWidth: 70, 
                    baseline: 'hanging', 
                }// options
            ); 

            if (!attachment) {
                column_number = 2;
            }
        }
    }

    function nextPage(write_section_heading) {
        page_body_y_start = 25;
        column_height = doc.internal.pageSize.height - page_body_y_start - 15;

        column_number = 1;

        doc.addPage();
        page_number += 1;
        addHeader();
        addFooter(page_number);

        next_item_y_pos = page_body_y_start;

        if (write_section_heading) {
            doc
                .setFont(CONTENT_HEADING_FONT, "normal")
                .setFontSize(10)
                .setTextColor(125, 128, 103)
                .text(
                section_name.toUpperCase(),
                X_POSITIONS[column_number], // x coordinate
                next_item_y_pos, // y coordinate
                { 
                    maxWidth: 70, 
                    baseline: 'hanging', 
                }// options
            ); 

            next_item_y_pos += 4 * Math.ceil(section_name.length / 32) + 7;
        }
    }

    function addTableStructure(primary) {        
        const column_width = (doc.internal.pageSize.width - 11 - 12) / 7;
        const column_start_x_pos = [];
        const sequences = [
            {
                number: 1,
                name: 'Organisational Structure',
                description: 'Time, Function in learning process, settings',
            },
            {
                number: 2,
                name: 'Aims, Rationales',
                description: 'Historical Thinking Concept',
            },
            {
                number: 3,
                name: 'Topics, Sub-Themes',
                description: 'Substantive Concepts',
            },
            {
                number: 4,
                name: 'Structure of Communication / Learning Organisation',
                description: 'Methods, Media',
            },
            {
                number: 5,
                name: 'Analysis, Interpretation, Transfer',
                description: 'Competence- building, Orientation',
            },
            {
                number: 6,
                name: 'Back-Coupling',
                description: 'Forms of Feedback, Reference to Learning Group',
            },
            {
                number: 7,
                name: 'Reflection',
                description: 'In Group and Self-Reflection',
            },
        ];

        for (let i = 0; i < 8; i++) {
            doc.line(12 + i * column_width, 20, 12 + i * column_width, 195);
            column_start_x_pos.push(12 + i * column_width);
        }

        let initial_y_pos = 0;

        if (primary) {
            initial_y_pos = 21;
            for (let item in sequences) {
                next_item_y_pos = initial_y_pos;

                doc
                    .setFont(CONTENT_HEADING_FONT, "normal")
                    .setFontSize(10)
                    .setTextColor(90, 129, 190)
                    .text(
                        String(sequences[item].number).toUpperCase(),
                        Number((column_start_x_pos[item] + column_start_x_pos[Number(item) + 1]) / 2), // x coordinate
                        next_item_y_pos, // y coordinate
                        { 
                            align: 'center',
                            maxWidth: 32, 
                            baseline: 'hanging', 
                        }
                    ); // options

                next_item_y_pos += 3 + 2; // line height + space

                doc
                    .setFont(CONTENT_HEADING_FONT, "normal")
                    .setFontSize(10)
                    .setTextColor(90, 129, 190)
                    .text(
                        sequences[item].name.toUpperCase(),
                        Number((column_start_x_pos[item] + column_start_x_pos[Number(item) + 1]) / 2), // x coordinate
                        next_item_y_pos, // y coordinate
                        { 
                            align: 'center',
                            maxWidth: 32, 
                            baseline: 'hanging',
                            lineHeightFactor: 1.2,
                        }
                    ); // options

                next_item_y_pos += Math.ceil(sequences[item].name.length / 13) * 4 + 5; // line height + space

                doc
                    .setFont(CONTENT_FONT, "normal")
                    .setFontSize(10)
                    .setTextColor(90, 129, 190)
                    .text(
                        sequences[item].description,
                        Number((column_start_x_pos[item] + column_start_x_pos[Number(item) + 1]) / 2), // x coordinate
                        next_item_y_pos, // y coordinate
                        { 
                            align: 'center',
                            maxWidth: 32, 
                            baseline: 'hanging', 
                            lineHeightFactor: 1.2,
                        }
                    ); // options
            }

            next_item_y_pos += 26;
        } else {
            initial_y_pos = 21;
            for (let item in sequences) {
                next_item_y_pos = initial_y_pos;

                doc
                    .setFont(CONTENT_FONT, "normal")
                    .setFontSize(9)
                    .setTextColor(90, 129, 190)
                    .text(
                        String(sequences[item].number).toUpperCase(),
                        Number((column_start_x_pos[item] + column_start_x_pos[Number(item) + 1]) / 2), // x coordinate
                        next_item_y_pos, // y coordinate
                        { 
                            align: 'center',
                            maxWidth: 32, 
                            baseline: 'hanging', 
                        }
                    ); // options

                next_item_y_pos += 3 + 5; // line height + space

                doc
                    .setFont(CONTENT_FONT, "normal")
                    .setFontSize(9)
                    .setTextColor(90, 129, 190)
                    .text(
                        sequences[item].name.toUpperCase(),
                        Number((column_start_x_pos[item] + column_start_x_pos[Number(item) + 1]) / 2), // x coordinate
                        next_item_y_pos, // y coordinate
                        { 
                            align: 'center',
                            maxWidth: 32, 
                            baseline: 'hanging',
                            lineHeightFactor: 1.6,
                        },
                    ); // options
            }

            next_item_y_pos += 20;
        }
        page_body_y_start = next_item_y_pos;
        column_height = doc.internal.pageSize.height - page_body_y_start - 15;
        available_left_column_space = column_height;
    }

    function addSequences(sequences) {
        // for every sequence add
        for (let sequence of sequences) {
            addSequence(sequence);
        }
    }

    // left sequence part is true when not a full sequence is given
    // but only the remaining of one before
    // in order to be able to use this function recursively
    function addSequence(sequence) {
        const PARAGRAPH_LINE_HEIGHT = 3;
        const CHARS_PER_LINE = 22;
        const SEQUENCE_NAMES = [
            'organisational_structure',
            'aims_rationales',
            'topics_sub_themes',
            'structure_of_communication',
            'analysis_interpretation_transfer',
            'back_coupling',
            'reflection',
        ];
        const column_width = (doc.internal.pageSize.width - 11 - 12) / 7;
        const column_start_x_pos = [];

        for (let i = 0; i < 8; i++) {
            doc.line(12 + i * column_width, 20, 12 + i * column_width, 195);
            column_start_x_pos.push(12 + i * column_width);
        }

        // get longest text in the sequence
        let longest_content = 0;

        for (let name of SEQUENCE_NAMES) {
            if (sequence[name].length > longest_content) {
                longest_content = sequence[name].length;
            }
        }

        // calculate the lines of the longest text
        let content_lines = Math.ceil(longest_content / CHARS_PER_LINE);

        if (available_left_column_space < 20) {
            nextPage();
            addTableStructure(false);
        }

        if (content_lines * PARAGRAPH_LINE_HEIGHT < available_left_column_space) {
            for (let name_index in SEQUENCE_NAMES) {
                doc
                    .setFont(CONTENT_FONT, "normal")
                    .setFontSize(8)
                    .setTextColor(30, 30, 30)
                    .text(
                        sequence[SEQUENCE_NAMES[name_index]],
                        column_start_x_pos[name_index] + 2, // x coordinate
                        next_item_y_pos, // y coordinate
                        { 
                            // align: 'justify',
                            maxWidth: 35, 
                            baseline: 'hanging', 
                        }
                    ); // options
            }
    
            next_item_y_pos += (content_lines * PARAGRAPH_LINE_HEIGHT + 8);
            available_left_column_space -= (content_lines * PARAGRAPH_LINE_HEIGHT + 8);

            if (available_left_column_space > 30) {
                doc.setDrawColor(90, 129, 190);
                doc.line(12, next_item_y_pos, doc.internal.pageSize.width - 11, next_item_y_pos);
                doc.setDrawColor(0, 0, 0);
            }
    
            next_item_y_pos += 10;
            available_left_column_space -= 10;
        } else {
            const left_lines = Math.floor(available_left_column_space / PARAGRAPH_LINE_HEIGHT);
            let remaining_sequence = {};

            for (let name_index in SEQUENCE_NAMES) {
                if (Math.ceil(sequence[SEQUENCE_NAMES[name_index]].length / CHARS_PER_LINE) < left_lines) {
                    // if the column space is enough for this text, just print it and set
                    // the remaining text for recursive to empty
                    doc
                        .setFont(CONTENT_FONT, "normal")
                        .setFontSize(8)
                        .setTextColor(30, 30, 30)
                        .text(
                            sequence[SEQUENCE_NAMES[name_index]],
                            column_start_x_pos[name_index] + 2, // x coordinate
                            next_item_y_pos, // y coordinate
                            { 
                                // align: 'justify',
                                maxWidth: 35, 
                                baseline: 'hanging', 
                            }
                        ); // options
                    remaining_sequence[SEQUENCE_NAMES[name_index]] = '';
                } else {
                    const index = sequence[SEQUENCE_NAMES[name_index]].substring((left_lines - 1) * CHARS_PER_LINE).indexOf(' ');
                    const string_to_print = sequence[SEQUENCE_NAMES[name_index]].substring(0, (left_lines - 1) * CHARS_PER_LINE + index);
                    remaining_sequence[SEQUENCE_NAMES[name_index]] = sequence[SEQUENCE_NAMES[name_index]].substring((left_lines - 1) * CHARS_PER_LINE + index);

                    doc
                        .setFont(CONTENT_FONT, "normal")
                        .setFontSize(8)
                        .setTextColor(30, 30, 30)
                        .text(
                            string_to_print,
                            column_start_x_pos[name_index] + 2, // x coordinate
                            next_item_y_pos, // y coordinate
                            { 
                                // align: 'justify',
                                maxWidth: 35, 
                                baseline: 'hanging', 
                            }
                        ); // options
                }
            }

            nextPage();
            addTableStructure(false);
            addSequence(remaining_sequence);
        }
    }

    function addAttachments(attachments) {
        // for every attachment add
        for (let attachment_number in attachments) {
            addAttachment(attachments[attachment_number], Number(attachment_number) + 1);
        }
    }

    function addAttachment(attachment, attachment_number) {
        const NAME_MAX_STRING_LENGTH = { 1: 42, 2: 55, 3: 55 };
        const DESCRIPTION_MAX_STRING_LENGTH = { 1: 46, 2: 60, 3: 60 };
        const MAX_WIDTH_FOR_COLUMN = { 1: 70, 2: 93, 3: 93 };

        let attachment_height = 0;
        attachment_height += Math.ceil(attachment.name.length / NAME_MAX_STRING_LENGTH[column_number]);
        attachment_height += 3;
        attachment_height += Math.ceil(attachment.description.length / DESCRIPTION_MAX_STRING_LENGTH[column_number]);


        if (available_left_column_space < attachment_height) {
            nextSection(true);
        }

        doc
            .setFont(CONTENT_FONT, "normal")
            .setFontSize(9)
            .setTextColor(30, 30, 30)
            .text(
                String(attachment_number),
                X_POSITIONS[column_number] - 0.2, // x coordinate
                next_item_y_pos - 1, // y coordinate
                { 
                    align: 'right',
                    baseline: 'hanging', 
                }
            ); // options

        doc
            .setFont(CONTENT_FONT, "normal")
            .setFontSize(10)
            .setTextColor(30, 30, 30)
            .text(
                attachment.name,
                X_POSITIONS[column_number], // x coordinate
                next_item_y_pos, // y coordinate
                { 
                    // align: 'justify',
                    maxWidth: MAX_WIDTH_FOR_COLUMN[column_number], 
                    baseline: 'hanging', 
                }
            ); // options

        next_item_y_pos += Math.ceil(attachment.name.length / NAME_MAX_STRING_LENGTH[column_number]) * PARAGRAPH_LINE_HEIGHT + 2; // line height + space
        available_left_column_space -= (Math.ceil(attachment.name.length / NAME_MAX_STRING_LENGTH[column_number]) * PARAGRAPH_LINE_HEIGHT + 2);

        if (attachment.description.length > 0) {
            doc
                .setFont(CONTENT_FONT, "normal")
                .setFontSize(8)
                .setTextColor(30, 30, 30)
                .text(
                    attachment.description,
                    X_POSITIONS[column_number], // x coordinate
                    next_item_y_pos, // y coordinate
                    { 
                        // align: 'justify',
                        maxWidth: MAX_WIDTH_FOR_COLUMN[column_number], 
                        baseline: 'hanging', 
                    }
                ); // options

            next_item_y_pos += Math.ceil(attachment.description.length / DESCRIPTION_MAX_STRING_LENGTH[column_number]) * PARAGRAPH_LINE_HEIGHT + 8; // line height + space
            available_left_column_space -= (Math.ceil(attachment.description.length / DESCRIPTION_MAX_STRING_LENGTH[column_number]) * PARAGRAPH_LINE_HEIGHT + 8);
        } else {
            next_item_y_pos += 4;
            available_left_column_space -= 4;
        }
    }

}

export { generatePdf }

