import { importFont as importFont1 } from './Avenir-normal';
import { importFont as importFont2 } from './Avenir-italic';
import { importFont as importFont3 } from './AvenirHeavy-normal';
import { importFont as importFont4 } from './AvenirHeavy-italic';
import { importFont as importFont5 } from './AvenirLight-normal';
import { importFont as importFont6 } from './AvenirLight-italic';
import { importFont as importFont7 } from './AvenirMedium-normal';
import { importFont as importFont8 } from './AvenirMedium-italic';

function importFonts() {
    return new Promise(resolve => {
        importFont1();
        importFont2();
        importFont3();
        importFont4();
        importFont5();
        importFont6();
        importFont7();
        importFont8();
        resolve();
    })
}

export { importFonts }