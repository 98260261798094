const resource = {
    en: {
        planungsmatrix: 'Planungsmatrix',
        save: 'save',
        share: 'share',
        set_status: 'Set Status',
        delete: 'delete',
        pdf: 'PDF',
        notifications: {
            updated: 'The Matrix Information was Updated',
            unauthorized: 'This matrix has not been published',
        },
    },
    de: {
        planungsmatrix: 'Planungsmatrix',
        save: 'speichern',
        share: 'Teilen',
        set_status: 'Status Ändern',
        delete: 'Löschen',
        pdf: 'PDF',
        notifications: {
            updated: 'Die Matrix wurde aktualisiert',
            unauthorized: 'Diese Matrix ist nicht veröffentlicht',
        },
    }
}

export default resource;