<template>
    <BaseModal ref="base_modal" @close="closeModal">
        <label class="modal-heading" v-html="resource[lang].heading" />

        <label class="old-data-heading modal-heading-2" v-html="resource[lang].old_data" />
        <div class="old-data">
            <InputText :label="resource[lang].name" v-model="old.name" readonly />
            <InputText :label="resource[lang].description" v-model="old.description" readonly />
        </div>

        <label class="new-data-heading modal-heading-2" v-html="resource[lang].new_data" />
        <form class="new-data" @submit.prevent="saveAttachment">
            <InputText :label="resource[lang].name" v-model="name" required />
            <InputText :label="resource[lang].description" v-model="description" />

            <div class="actions flex">
                <Button color="accent" :label="resource[lang].save" submit />
                <Button color="white" :label="resource[lang].discard" @click="closeModal" />
            </div>
            
        </form>

    </BaseModal>
</template>

<script>
import resource from '@/assets/resources/file-edit-modal-resource'

import BaseModal from '@/components/modals/BaseModal'
import Button from '@/components/basic/Button'
import InputText from '@/components/basic/InputText'

import { promisePutRequest } from '@/assets/js/axios-utilities'

export default {
    props: {
        attachment: {
            type: Object,
            required: true,
        }
    },
    components: {
        BaseModal,
        Button,
        InputText
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            old: {
                name: '',
                description: '',
            },

            name: '',
            description: '',

            resource
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        // Init
        init() {
            this.name = this.attachment.name;
            this.old.name = this.attachment.name;
            this.description = this.attachment.description;
            this.old.description = this.attachment.description;
        },

        // Requests
        updateAttachmentRequest(request_body) {
            return promisePutRequest(`attachment/${this.attachment.id}`, request_body);
        },

        // Actions
        async saveAttachment() {
            const request_body = {
                ...this.attachment,
                name: this.name,
                description: this.description,
            };

            this.$store.dispatch('loading', true);
            const response = await this.updateAttachmentRequest(request_body);
            this.$store.dispatch('loading', false);

            this.$emit('update', response.data.file);
            this.$refs.base_modal.closeModal();
        },
        closeModal() {
            this.$emit('cancel');
            this.$refs.base_modal.closeModal();
        }
    },
    emits: ['update', 'cancel'],
}
</script>

<style lang="scss" scoped>

.actions {
    padding-block-start: 1rem;
    justify-content: space-between;
}

.icon {
    position: relative;

    height: 100%;

    a {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
    }

    .file-icon {
        display: block;
        margin: auto;
    }
}

.new-data  > * + * {
    margin-block-start: 1rem;
}

.old-data > * + * {
    margin-block-start: 1rem;
}

</style>