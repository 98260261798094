<template>
    <BaseModal ref="base_modal" @close="cancel">
        <label class="modal-heading" v-html="resource[lang].heading" />
        <ul class="status-list">
            <li v-html="resource[lang].in_edit" @click="setStatus('in_edit')" tabindex="0" />
            <li v-html="resource[lang].for_review" @click="setStatus('for_review')" tabindex="0" />
            <li v-html="resource[lang].finish" @click="setStatus('finish')" tabindex="0" />
            <li v-if="admin" v-html="resource[lang].public" @click="setStatus('public')" tabindex="0" />
        </ul>
    </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import resource from '@/assets/resources/set-status-modal-resource'

import { getAbility } from '@/assets/js/user-abilities';

import BaseModal from '@/components/modals/BaseModal'

export default {
    components: {
        BaseModal
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            admin: false,

            resource
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    created() {
        this.getUserAbility();
    },
    methods: {
        // init
        async getUserAbility() {
            this.admin = await getAbility('admin', this.user, null);
        }, 
        setStatus(status) {
            this.$refs.base_modal.closeModal();
            this.$emit('setStatus', status);
        },
        cancel() {
            this.$refs.base_modal.closeModal();
            this.$emit('cancel');
        }
    },
    watch: {
        user() {
            this.getUserAbility();
        },
    },
    emits: ['setStatus', 'cancel']
}
</script>

<style lang="scss" scoped>

.status-list {
    list-style: none;

    li {
        cursor: pointer;

        background-color: var(--whit);
        color: var(--primary);

        font-family: var(--standard-font);
        font-size: 1.25rem;
        font-weight: 500;

        text-transform: uppercase;

        padding: 2rem;
    }

    li + li {
        margin-block-start: 0.5rem;
    }

    li:hover, li:focus-visible {
        color: var(--blak);
        border: solid 1px var(--blak);
        margin-block-end: -2px;
    }
}

</style>