<template>
    <div>
        <h1 class="page-heading" v-html="resource[lang].planungsmatrix" />

        <MatrixForm 
            v-if="matrix || new_matrix" 
            ref="matrix_form" 
            :matrix="matrix" 
            @reload="init" 
        />

        <MatrixSequences 
            class="sequences" 
            v-if="matrix" 
            :matrix="matrix" 
            @reload="init" 
            @save="$refs.matrix_form.submit()" 
        />

        <MatrixAttachments 
            class="attachments" 
            v-if="matrix" 
            :matrix="matrix" 
            @reload="init" 
        />

        <MatrixSharedUsers 
            class="shared-users" 
            v-if="matrix && user" 
            :matrix="matrix" 
            @reload="init" 
        />

        <div class="actions flex">
            <div>
                <Button 
                    color="accent" 
                    v-if="create || update" 
                    :label="resource[lang].save" 
                    @click="$refs.matrix_form.submit()" 
                />
                <Button 
                    color="secondary" 
                    v-if="share" 
                    :label="resource[lang].share" 
                    @click="showShareModal" 
                />
                <Button 
                    color="secondary" 
                    v-if="update" 
                    :label="resource[lang].set_status" 
                    @click="showSetStatusModal" 
                />
                <Button 
                    color="secondary" 
                    v-if="!create && share" 
                    :label="resource[lang].pdf" 
                    @click="getPdf" 
                />
            </div>

            <div>
                <Button 
                    color="secondary" 
                    v-if="delet" 
                    :label="resource[lang].delete" 
                    @click="showDeleteModal" 
                />
            </div>
            
        </div>

        <DeleteModal 
            v-if="showDeleteModalFlag" 
            @confirm="confirmDelete"
            @cancel="cancelDelete"
        />

        <ShareModal
            v-if="showShareModalFlag"
            :matrix="matrix"
            @share="handleShare"
            @cancel="cancelShare"
        />

        <SetStatusModal
            v-if="showSetStatusModalFlag"
            @setStatus="setStatus"
            @cancel="cancelSetStatus"
        />
        
    </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex'
import { useQuasar } from 'quasar'

import resource from '@/assets/resources/matrix-resource'

import Button from '@/components/basic/Button'
import DeleteModal from '@/components/modals/DeleteModal'
import MatrixAttachments from '@/components/MatrixAttachments'
import MatrixForm from '@/components/forms/MatrixForm'
import MatrixSequences from '@/components/MatrixSequences'
import MatrixSharedUsers from '@/components/MatrixSharedUsers'
import ShareModal from '@/components/modals/ShareModal'
import SetStatusModal from '@/components/modals/SetStatusModal'

import { promiseDeleteRequest, promiseGetRequest, promisePutRequest } from '@/assets/js/axios-utilities' 
import { getAbility } from '@/assets/js/user-abilities';

import { generatePdf } from '@/assets/js/generate-pdf'; 

export default {
    components: {
        Button,
        DeleteModal,
        MatrixAttachments,
        MatrixForm,
        MatrixSequences,
        MatrixSharedUsers,
        ShareModal,
        SetStatusModal
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            matrix: null,

            // modals
            showShareModalFlag: false,
            showDeleteModalFlag: false,
            showSetStatusModalFlag: false,

            // abilities
            create: false,
            delet: false,
            update: false,
            share: false,

            // from mode
            new_matrix: false,

            resource,
            axios,
        }
    },
    computed: {
        ...mapGetters(['user']),
        matrix_id() {
            return this.$route.params.id ? this.$route.params.id : null;
        },
    },
    setup() {
        const quasar = useQuasar();
        
        return { 
            quasar
        }
    },
    created() {
        this.init();
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        // Init
        async init() {
            if (this.matrix_id) {
                this.$store.dispatch('loading', true);
                const response = await this.getMatrixRequest().catch(() => { 
                    this.$router.push('/login'); 
                    this.$store.dispatch('loading', false); 

                    this.quasar.notify({
                        position: 'bottom-left', 
                        message: this.resource[this.lang].notifications.unauthorized,
                        color: 'negative'
                    });
                });
                this.$store.dispatch('loading', false);
                this.matrix = response ? response.data : undefined;
            } else {
                this.new_matrix = true;
            }
            this.getAbilities();
        },
        async getAbilities() {
            if (this.matrix_id) {
                this.delet = await getAbility('delete-matrix', this.user, this.matrix)
                this.share = await getAbility('share-matrix', this.user, this.matrix)
                this.update = await getAbility('update-matrix', this.user, this.matrix)
            } else {
                this.create = await getAbility('create-matrix', this.user)
            }
        },

        // Requests
        getMatrixRequest() {
            return promiseGetRequest(`matrix/${this.matrix_id}`);
        },
        deleteMatrixRequest() {
            return promiseDeleteRequest(`matrix/${this.matrix_id}`);
        },
        shareMatrixRequest(request_body) {
            return promisePutRequest(`share-matrix/${this.matrix_id}`, request_body);
        },
        updateMatrixRequest(request_body) {
            return promisePutRequest(`matrix/${this.matrix_id}`, request_body);
        },

        // Actions
        showDeleteModal() {
            this.showDeleteModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        showShareModal() {
            this.showShareModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        showSetStatusModal() {
            this.showSetStatusModalFlag = true;
            this.$store.dispatch('modal', true);
        },
        cancelDelete() {
            this.showDeleteModalFlag = false;
        },
        cancelShare() {
            this.showShareModalFlag = false;
        },
        cancelSetStatus() {
            this.showSetStatusModalFlag = false;
        },
        async confirmDelete() {
            this.$store.dispatch('loading', true);
            await this.deleteMatrixRequest();
            this.$store.dispatch('loading', false);

            this.$router.push('/dashboard/my-matrices')
        },
        async handleShare(user_ids, can_edit) {
            const request_body = {
                user_ids: user_ids,
                can_edit: can_edit
            }
            this.$store.dispatch('loading', true);
            await this.shareMatrixRequest(request_body);
            this.$store.dispatch('loading', false);

            this.showShareModalFlag = false;   
            this.init()
        },
        async setStatus(status) {
            this.matrix.status = status;

            this.$store.dispatch('loading', true);
            this.updateMatrixRequest(this.matrix);
            this.$store.dispatch('loading', false);

            // this.init(); 

            this.quasar.notify({
                position: 'bottom-left', 
                message: this.resource[this.lang].notifications.updated,
                color: 'positive'
            });
        },

        // pdf
        getPdf() {
            generatePdf(this.matrix);
        }
    },
    watch: {
        user: function() {
            this.getAbilities()
        }
    },
    // check if user is logged in before enter
    // beforeRouteEnter(to, from, next) {
    //     const token = localStorage.getItem('jwt_token');

    //     if (token) {
    //         next()
    //     } else {
    //         next({name: 'Login'})
    //     }
    // }, 
}
</script>

<style lang="scss" scoped>

.actions {
    margin-block-start: 2rem;

    justify-content: space-between;

    div {
        & > * + * {
            margin-inline-start: 1rem;
        }
    }
}

.sequences,
.attachments,
.shared-users  {
    margin-block-start: 5rem;
}

@media (max-width: 40rem) {
    .actions {
        display: block;
    }

    .actions button {
        margin: 0;
        margin-block-start: 1rem;
        width: 100%;
    }
}

</style>