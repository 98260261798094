const resource = {
    en: {
        heading: 'End Sharing',
        text: 'Please confirm that you want to remove the selected user from your matrix',
        confirm: 'Confirm',
        cancel: 'Cancel'
    },
    de: {
        heading: 'Freigabe beenden',
        text: 'Bitte bestätigen Sie, dass Sie die Matrix für den ausgewählen Benutzer nicht mehr freigeben möchten.',
        confirm: 'Bestätigen',
        cancel: 'Abbrechen'
    }
}

export default resource;