<template>
    <div class="attachment flex">
        <div>
            <a class="text" :href="attachment.route" v-html="attachment.name" target="_blank" />
        </div>
        <div>
            <q-icon 
                name="fas fa-pen" 
                size="1rem" 
                color="dark_grey"
                class="action-icon"
                tabindex="0"
                @click="$emit('edit-attachment', attachment)"
            >
                <q-tooltip v-html="resource[lang].tooltips.edit" />
            </q-icon>

            <q-icon 
                name="fas fa-times" 
                size="1rem" 
                color="dark_grey"
                class="action-icon"
                tabindex="0"
                @click="$emit('delete-attachment', attachment.id)"
            >
                <q-tooltip v-html="resource[lang].tooltips.remove" />
            </q-icon>
        </div>
    </div>
</template>

<script>
import resource from '@/assets/resources/matrix-attachment-list-item-resource'

export default {
    props: {
        attachment: {
            required: true,
            type: Object
        }
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,
            
            resource
        }
    },
    emits: ['edit-attachment', 'delete-attachment']
}
</script>

<style lang="scss" scoped>

a {
    color: var(--blak);
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
}

.action-icon {
    cursor: pointer;
}

.action-icon + .action-icon {
    margin-left: 1rem;
}

.attachment {
    justify-content: space-between;

    background-color: var(--whit);
    padding: 1rem;
}

</style>