const resource = {
    en: {
        heading: 'Matrix Attachments',
        add_attachment: 'Add Attachment',
        no_attachments: 'There are no attachments to the matrix.',
    },
    de: {
        heading: 'Anhänge zur Matrix',
        add_attachment: 'Anhang hinzufügen',
        no_attachments: 'Matrix enthält keine Anhänge.',
    }
}

export default resource;